import React from 'react'
import img from '../../images/hifu-twarz-zmiana.webp'
import {Link} from 'gatsby'
import Layout from '../../components/Layout'
import Fade from 'react-reveal'
import {Seo} from '../../components/Seo'

const HIFUTwarz = () => {
    return (
        <>
            <Layout>
                <Seo
                    title='HIFU | Modelowanie Sylwetki'
                    description='HIFU to zabieg, który jest nazywany nieinwazyjnym liftingiem bez
          użycia skalpela. Jego pełna nazwa to High Intensity Focused
          Ultrasound. Zabieg jest alternatywą dla osób, które chcą wyglądać
          młodo jednak nie akceptują lub unikają zabiegów z użyciem skalpela.'
                    url='https://modelowaniesylwetki-wawer.pl/zabiegi-na-twarz/hifu'
                />

                <div className='page-container'>
                    <div className='page'>
                        <div className='content-1'>
                            <h1>HIFU</h1>
                            <div className='section'>
                                <div className='image-container long'>
                                    <img
                                        src={img}
                                        width='1000px'
                                        height='667px'
                                        alt='hifu zdjęcie'
                                    ></img>
                                </div>

                                <div className='text'>
                                    <p>
                                        HIFU to zabieg, który jest nazywany nieinwazyjnym
                                        liftingiem bez użycia skalpela. Jego pełna nazwa
                                        to High Intensity Focused Ultrasound. Zabieg jest
                                        alternatywą dla osób, które chcą wyglądać młodo
                                        jednak nie akceptują lub unikają zabiegów z
                                        użyciem skalpela.
                                    </p>
                                    <p>
                                        Zabieg HIFU działa przeciwstarzeniowo, zmniejsza
                                        powstające wraz z wiekiem zmarszczki czy
                                        zwiotczenia skórne. HIFU 3D to działanie
                                        skoncentrowaną wiązką ultradźwięków o różnej
                                        częstotliwości i głębokości, które nie uszkadza
                                        skóry a koncentruje się w głębokich warstwach
                                        skóry lub w tkance podskórnej. Dochodzi do
                                        podgrzania tkanek podskórnych powodując ich
                                        koagulację. Efektem tego działania jest lifting
                                        skóry oraz produkcja nowego kolagenu. Zabieg
                                        poprawia owal twarzy, wyostrza kontur, wyraźnie
                                        liftinguje skórę.
                                    </p>
                                    <p>
                                        Nasze urządzenie pozwala na przeprowadzanie
                                        zabiegów z użyciem wieloliniowych kartridży.
                                        Dzięki temu możliwe jest uzyskanie jeszcze
                                        lepszego pokrycia pola zabiegowego przy mniejszej
                                        gęstości „strzałów”. Jest to idealne rozwiązanie
                                        dla osób chcących znacząco poprawić swój wygląd
                                        bez metod inwazyjnych.
                                    </p>
                                    <div className='cennik'>
                                        <span>CENNIK</span>
                                        <h2>HIFU</h2>
                                        <ul>
                                            <li>
                                                <p>HIFU CAŁA TWARZ - POJEDYNCZY ZABIEG</p>
                                                <span className='prices'>2000 zł</span>
                                            </li>
                                            <li>
                                                <p>
                                                    HIFU CAŁA TWARZ + PODBRÓDEK -
                                                    POJEDYNCZY ZABIEG
                                                </p>
                                                <span className='prices'>2700 zł</span>
                                            </li>
                                            <li>
                                                <p>HIFU CHOMIKI - POJEDYNCZY ZABIEG</p>
                                                <span className='prices'>800 zł</span>
                                            </li>
                                            <li>
                                                <p>HIFU CZOŁO - POJEDYNCZY ZABIEG</p>
                                                <span className='prices'>500 zł</span>
                                            </li>
                                            <li>
                                                <p>HIFU PODBRÓDEK - POJEDYNCZY ZABIEG</p>
                                                <span className='prices'>900 zł</span>
                                            </li>
                                            <li>
                                                <p>
                                                    HIFU SZYJA + PODBRÓDEK - POJEDYNCZY
                                                    ZABIEG
                                                </p>
                                                <span className='prices'>1250 zł</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Fade left>
                            <Link to='/kontakt'>
                                <button>Umów Wizytę</button>
                            </Link>
                        </Fade>
                    </div>
                </div>
            </Layout>
        </>
    )
}

export default HIFUTwarz
